<template>
  <v-card flat tile class="charcoal noscroll-x">
    <v-form ref="exerciseForm" name="exerciseForm">
      <v-sheet color="transparent" class="paper--text">
        <BaseCloseDialogHeader
          @cancel="$emit('cancel')"
          heading=" Add New Exercise"
        />
      </v-sheet>
      <v-card-actions class="pa-0">
        <v-spacer />
        <v-checkbox
          dark
          color="progressActive"
          ref="quick"
          v-model="quick"
          class="pa-0 ma-0 mr-4 mt-0"
        >
          <template v-slot:label>
            <span class="silver--text">
              Quick Mode?
            </span>
          </template>
        </v-checkbox>
      </v-card-actions>
      <v-row dense>
        <v-col cols="12" md="3">
          <v-sheet
            color="transparent charcoalTileMenu"
            class="pa-2 elevation-1"
          >
            <v-card-actions class="paper--text pl-0">
              Exercise Type <v-divider class="charcoal lighten-1 ml-2 " />
            </v-card-actions>
            <v-switch
              dark
              ref="resistance"
              false-value="No"
              true-value="Yes"
              v-model="resistance"
              color="progressActive"
            >
              <template v-slot:label>
                <span class="pa-0" v-tooltip.bottom="tooltips['resistance']">
                  Resistance
                  <v-icon small class="progressActive--text mt-n1">
                    mdi-help
                  </v-icon>
                </span>
              </template>
            </v-switch>
            <v-switch
              dark
              ref="bodyweight"
              false-value="No"
              true-value="Yes"
              v-model="bodyweight"
              color="progressActive"
            >
              <template v-slot:label>
                <span class="pa-0" v-tooltip.bottom="tooltips['bodyweight']">
                  Bodyweight
                  <v-icon small class="progressActive--text mt-n1">
                    mdi-help
                  </v-icon>
                </span>
              </template>
            </v-switch>
            <v-switch
              dark
              ref="calisthenics"
              false-value="No"
              true-value="Yes"
              v-model="calisthenics"
              color="progressActive"
            >
              <template v-slot:label>
                <span class="pa-0" v-tooltip.bottom="tooltips['calisthenics']">
                  Calisthenics
                  <v-icon small class="progressActive--text mt-n1">
                    mdi-help
                  </v-icon>
                </span>
              </template>
            </v-switch>
            <v-switch
              dark
              ref="cardio"
              false-value="No"
              true-value="Yes"
              v-model="cardio"
              color="progressActive"
            >
              <template v-slot:label>
                <span class="pa-0" v-tooltip.bottom="tooltips['cardio']">
                  Cardio
                  <v-icon small class="progressActive--text mt-n1">
                    mdi-help
                  </v-icon>
                </span>
              </template>
            </v-switch>
            <v-switch
              dark
              ref="bilateral"
              false-value="No"
              true-value="Yes"
              v-model="bilateral"
              color="progressActive"
            >
              <template v-slot:label>
                <span class="pa-0" v-tooltip.bottom="tooltips['bilateral']">
                  Bilateral
                  <v-icon small class="progressActive--text mt-n1">
                    mdi-help
                  </v-icon>
                </span>
              </template>
            </v-switch>
            <v-switch
              dark
              ref="track_distance"
              false-value="No"
              true-value="Yes"
              v-model="track_distance"
              color="progressActive"
            >
              <template v-slot:label>
                <span
                  class="pa-0"
                  v-tooltip.bottom="tooltips['track_distance']"
                >
                  Track Distance
                  <v-icon small class="progressActive--text mt-n1">
                    mdi-help
                  </v-icon>
                </span>
              </template>
            </v-switch>
            <v-switch
              dark
              ref="track_time"
              false-value="No"
              true-value="Yes"
              v-model="track_time"
              color="progressActive"
            >
              <template v-slot:label>
                <span class="pa-0" v-tooltip.bottom="tooltips['track_time']">
                  Track Time
                  <v-icon small class="progressActive--text mt-n1">
                    mdi-help
                  </v-icon>
                </span>
              </template>
            </v-switch>
            <v-card-actions class="paper--text pl-0 pb-0">
              Visibility <v-divider class="ml-2 charcoal lighten-1" />
            </v-card-actions>
            <v-switch
              dark
              ref="public"
              false-value="No"
              true-value="Yes"
              label="Public ?"
              v-model="ispublic"
              color="progressActive"
            />
          </v-sheet>
        </v-col>

        <v-col cols="12" md="8">
          <v-sheet rounded class="pa-2 transparent charcoalTileMenu">
            <v-row dense>
              <v-col cols="12">
                <v-text-field
                  dark
                  v-model="name"
                  ref="name"
                  :error-messages="nameErrors"
                  :counter="50"
                  label="Name"
                  prepend-icon="mdi-dumbbell"
                  @input="$v.name.$touch()"
                  @blur="
                    $v.name.$touch()
                    checkCode()
                  "
                />
              </v-col>
              <v-col cols="12">
                <v-card-text
                  class="py-0 mt-n2 pl-8 caption progressActive--text"
                >
                  <span class="silver--text">Generated Code: </span>
                  {{ code }}
                </v-card-text>
                <v-card-text
                  v-if="!codeUnique"
                  class="py-0 ml-4 caption accent--text"
                >
                  <span class="red--text"
                    >Code is not unqiue, please make sure the exercise does not
                    exist. To continue, change the exercise name.
                  </span>
                </v-card-text>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <v-select
                  dark
                  v-model="dificulty"
                  :items="dificultyList"
                  label="Difficulty"
                  hint="What is the exercise difficulty?"
                  persistent-hint
                  item-text="name"
                  item-value="value"
                  prepend-icon="mdi-chevron-right-box"
                />
              </v-col>
              <v-col cols="12" v-if="!quick">
                <v-textarea
                  dark
                  v-model="description"
                  ref="description"
                  :error-messages="descriptionErrors"
                  :counter="250"
                  :rows="3"
                  label="Description"
                  prepend-icon="mdi-text"
                  @input="$v.description.$touch()"
                  @blur="$v.description.$touch()"
                />
              </v-col>
              <v-col v-else cols="12">
                <v-autocomplete
                  dark
                  clearable
                  ref="primary"
                  filled
                  v-model="primary"
                  :items="musclesList"
                  label="Primary Muscles"
                  multiple
                  chips
                  hint="What are the primary muscles targetted?"
                  persistent-hint
                  item-text="display"
                  item-value="id"
                  item-color="success"
                  append-icon="mdi-arm-flex"
                  color="success"
                  :menu-props="{
                    closeOnClick: true,
                    maxHeight: 250,
                    transition: 'fab-transition'
                  }"
                >
                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index === 0">
                      <span>{{ item.display }}</span>
                    </v-chip>
                    <span v-if="index === 1" class="silver--text text-caption">
                      (+{{ primary.length - 1 }} others)
                    </span>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-sheet>
        </v-col>
      </v-row>
      <v-sheet
        v-if="!quick"
        rounded
        class="px-2 my-2 transparent charcoalTileMenu"
      >
        <v-card-title :class="textSize + ' paper--text'">
          Detailed Instructions
        </v-card-title>
        <tiptap-vuetify
          dark
          v-model="instructions"
          :extensions="extensions"
          placeholder="Write your exercise instructions here.."
          :toolbar-attributes="{ color: 'charcoal', dark: true }"
          :card-props="{
            flat: true,
            class: 'charcoal lighten-1 silver--text'
          }"
        />
      </v-sheet>
      <template v-if="!quick">
        <v-card-title :class="textSize + ' paper--text py-0'">
          Target Muscles
          <v-divider class="charcoal lighten-1 ml-2" />
        </v-card-title>
        <v-col cols="11">
          <v-autocomplete
            dark
            clearable
            ref="primary"
            filled
            v-model="primary"
            :items="musclesList"
            label="Primary Muscles"
            multiple
            chips
            hint="What are the primary muscles targetted?"
            persistent-hint
            item-text="display"
            item-value="id"
            item-color="success"
            append-icon="mdi-arm-flex"
            color="success"
            :menu-props="{
              closeOnClick: true,
              maxHeight: 250,
              transition: 'fab-transition'
            }"
          >
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index === 0">
                <span>{{ item.display }}</span>
              </v-chip>
              <span v-if="index === 1" class="silver--text text-caption">
                (+{{ primary.length - 1 }} others)
              </span>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="11">
          <v-autocomplete
            dark
            clearable
            v-model="secondary"
            filled
            ref="secondary"
            :items="musclesList"
            label="Secondary Muscles"
            multiple
            chips
            deletable-chips
            hint="What are the secondary muscles targetted?"
            persistent-hint
            item-text="display"
            item-value="id"
            item-color="success"
            append-icon="mdi-arm-flex"
            color="success"
            :menu-props="{
              closeOnClick: true,
              maxHeight: 250,
              transition: 'fab-transition'
            }"
          >
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index === 0">
                <span>{{ item.display }}</span>
              </v-chip>
              <span v-if="index === 1" class="silver--text text-caption">
                (+{{ secondary.length - 1 }} others)
              </span>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="11">
          <v-autocomplete
            dark
            clearable
            v-model="stabilizers"
            filled
            ref="stabilizers"
            :items="musclesList"
            label="Stabilizing Muscles"
            multiple
            chips
            deletable-chips
            hint="What are the stabilizing muscles?"
            persistent-hint
            item-text="display"
            item-value="id"
            item-color="success"
            append-icon="mdi-arm-flex"
            class="success--text"
            color="success"
            :menu-props="{
              closeOnClick: true,
              maxHeight: 250,
              transition: 'fab-transition'
            }"
          >
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index === 0">
                <span>{{ item.display }}</span>
              </v-chip>
              <span v-if="index === 1" class="silver--text text-caption">
                (+{{ stabilizers.length - 1 }} others)
              </span>
            </template>
          </v-autocomplete>
        </v-col>
      </template>

      <template v-if="!quick">
        <v-card-title :class="textSize + ' paper--text py-0'">
          Equipment
          <v-divider class="charcoal lighten-1 ml-2" />
        </v-card-title>

        <v-col cols="11">
          <v-autocomplete
            dark
            clearable
            filled
            ref="equipment"
            v-model="equipment"
            :items="equipmentList"
            label="Equipment Needed"
            multiple
            chips
            deletable-chips
            hint="What is the equipment needed to perform this exercise?"
            persistent-hint
            item-text="name"
            item-value="id"
            item-color="success"
            append-icon="mdi-jump-rope"
            class="success--text"
            color="success"
            :menu-props="{
              closeOnClick: true,
              maxHeight: 250,
              transition: 'fab-transition'
            }"
          >
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index === 0">
                <span>{{ item.name }}</span>
              </v-chip>
              <span v-if="index === 1" class="silver--text text-caption">
                (+{{ equipment.length - 1 }} others)
              </span>
            </template>
          </v-autocomplete>
        </v-col>
      </template>

      <v-card-actions :class="textSizeSmall + ' primary--text pb-0'">
        Force Type: {{ theforceType.name }}
        <v-divider class="ml-2" />
      </v-card-actions>
      <v-row dense class="pl-2">
        <v-col
          cols="6"
          md="3"
          v-for="theforce in theforces"
          :key="theforce.name"
        >
          <v-card
            width="150"
            height="150"
            class="elevation-5 mb-3 title"
            :color="tileColor(theforce.name === theforceType.name)"
            @click="settheforce(theforce)"
          >
            <v-card-actions
              :class="textColor(theforce.name === theforceType.name)"
            >
              {{ theforce.name }}
            </v-card-actions>
            <v-card-actions class="pt-10">
              <v-icon
                :color="iconColor(theforce.name === theforceType.name)"
                x-large
              >
                {{ theforce.icon }}
              </v-icon>
              <v-spacer />
              <v-icon v-if="theforce.name === theforceType.name" color="paper"
                >mdi-check-circle
              </v-icon>
            </v-card-actions>
          </v-card>
        </v-col>
        <blockquote
          v-html="theforceType.details"
          class="caption silver--text pl-2"
        />
      </v-row>
      <v-card-actions :class="textSizeSmall + ' primary--text pb-0'">
        Mechanics
        <v-divider class="ml-2" />
      </v-card-actions>
      <v-row class="pl-2" dense>
        <v-col cols="6" md="3" v-for="mech in mechanics" :key="mech.name">
          <v-card
            width="150"
            height="150"
            @click="setMechanics(mech)"
            class="elevation-5 mb-3 title"
            :color="tileColor(mech.name === mechanicsType.name)"
          >
            <v-card-actions
              :class="textColor(mech.name === mechanicsType.name)"
            >
              {{ mech.name }}
            </v-card-actions>
            <v-card-actions class="pt-10">
              <v-icon
                :color="iconColor(mech.name === mechanicsType.name)"
                x-large
              >
                {{ mech.icon }}
              </v-icon>
              <v-spacer />
              <v-icon
                v-if="mech.name === mechanicsType.name"
                color="paper"
                right
                >mdi-check-circle
              </v-icon>
            </v-card-actions>
          </v-card>
        </v-col>
        <blockquote
          v-html="mechanicsType.details"
          class="caption silver--text pl-2"
        />
      </v-row>
      <v-card-actions :class="textSizeSmall + ' primary--text pb-0'">
        Utility
        <v-divider class="ml-2" />
      </v-card-actions>
      <v-row class="pl-2" dense>
        <v-col cols="6" md="3" v-for="util in utility" :key="util.name">
          <v-card
            width="150"
            height="150"
            @click="setUtility(util)"
            class="elevation-5 mb-3 title"
            :color="tileColor(util.name === utilityType.name)"
          >
            <v-card-actions :class="textColor(util.name === utilityType.name)">
              {{ util.name }}
            </v-card-actions>
            <v-card-actions class="pt-10">
              <v-icon
                :color="iconColor(util.name === utilityType.name)"
                x-large
              >
                {{ util.icon }}
              </v-icon>
              <v-spacer />
              <v-icon v-if="util.name === utilityType.name" color="paper" right
                >mdi-check-circle
              </v-icon>
            </v-card-actions>
          </v-card>
        </v-col>
        <blockquote
          v-html="utilityType.details"
          class="caption silver--text pl-2"
        />
      </v-row>

      <v-card-actions class=" px-0">
        <v-spacer />
        <BaseActionButton
          :disabled="$v.$anyError || !codeUnique"
          label="Add Exercise"
          icon="mdi-plus-circle-outline"
          color="paper"
          text
          dark
          :large="!isPhone"
          @clickedThis="saveExercise()"
        />
      </v-card-actions>
    </v-form>
  </v-card>
</template>
<script>
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem
} from 'tiptap-vuetify'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { appConfig } from '@/store/helpers.js'
import axios from 'axios'
import util from '@/mixins/util.js'
import tooltips from '@/json/tooltips.json'

export default {
  components: { TiptapVuetify },
  mixins: [validationMixin, util],
  data: () => ({
    codeUnique: true,
    tooltips: tooltips,
    theforces: [],
    mechanics: [],
    utility: [],
    mechanicsType: { name: 'Compound' },
    utilityType: { name: 'Basic' },
    theforceType: { name: 'Push' },
    quick: true,
    name: '',
    oldname: '',
    dificulty: 'Intermediate',
    description: 'TBD',
    instructions: '',
    resistance: 'Yes',
    bodyweight: 'No',
    cardio: 'No',
    calisthenics: 'No',
    bilateral: 'No',
    track_distance: 'No',
    track_time: 'No',
    ispublic: 'Yes',
    primary: [],
    secondary: [],
    stabilizers: [],
    equipment: [],
    musclesList: [],
    equipmentList: [],
    dificultyList: [],
    glossary: {},
    showSuccess: false,
    extensions: [
      Paragraph,
      Bold,
      Italic,
      [
        Heading,
        {
          options: {
            levels: [5, 6]
          }
        }
      ],

      ListItem,
      BulletList,
      OrderedList
    ]
  }),
  beforeMount() {
    this.loadMusclesDefinition()
    this.loadEquipment()
    this.loadGlossary()
  },
  mounted() {
    //this.$refs.name.focus()
  },
  validations: {
    name: {
      required
    },
    description: {
      required
    }
  },
  computed: {
    code() {
      return this.generateCode(this.name)
    },
    nameErrors() {
      const errors = []
      if (!this.$v.name.$dirty) return errors
      !this.$v.name.required && errors.push('Exercise name is required.')
      return errors
    },
    descriptionErrors() {
      const errors = []
      if (!this.$v.description.$dirty) return errors
      !this.$v.description.required && errors.push('Description is required.')
      return errors
    },
    ...appConfig
  },
  methods: {
    tileColor(selected) {
      return selected ? 'charcoal charcoalTileMenu' : 'charcoal'
    },
    textColor(selected) {
      return selected ? 'paper--text' : 'silver--text'
    },
    iconColor(selected) {
      return selected ? 'paper' : 'silver'
    },
    addMore() {
      this.showSuccess = false
      this.$v.$reset()
      if (this.$refs.name) this.$refs.name.focus()
    },
    settheforce(name) {
      this.theforceType = name
    },
    setMechanics(name) {
      this.mechanicsType = name
    },
    setUtility(name) {
      this.utilityType = name
    },
    saveExercise() {
      let exercise = {}
      exercise.name = this.name
      exercise.dificulty = this.dificulty
      exercise.description = this.description
      exercise.instructions = this.instructions
      exercise.primary = this.primary
      exercise.secondary = this.secondary
      exercise.stabilizers = this.stabilizers
      exercise.equipment = this.equipment
      exercise.theforce = this.theforceType.name
      exercise.mechanics = this.mechanicsType.name
      exercise.utility = this.utilityType.name
      exercise.resistance = this.resistance
      exercise.bodyweight = this.bodyweight
      exercise.track_distance = this.track_distance
      exercise.track_time = this.track_time
      exercise.cardio = this.cardio
      exercise.calisthenics = this.calisthenics
      exercise.bilateral = this.bilateral
      exercise.ispublic = this.ispublic
      exercise.code = this.code
      return axios
        .post(this.baseURL + '/exercises', {
          name: this.name,
          dificulty: this.dificulty,
          description: this.description,
          instructions: this.instructions,
          primary: this.primary,
          secondary: this.secondary,
          stabilizers: this.stabilizers,
          equipment: this.equipment,
          theforce: this.theforceType.name,
          mechanics: this.mechanicsType.name,
          utility: this.utilityType.name,
          resistance: this.resistance,
          bodyweight: this.bodyweight,
          track_distance: this.track_distance,
          track_time: this.track_time,
          cardio: this.cardio,
          calisthenics: this.calisthenics,
          bilateral: this.bilateral,
          public: this.ispublic,
          code: this.code
        })
        .then(response => {
          if (response.status == 200) {
            exercise.id = response.data.data.exerciseid
            this.oldname = this.name
            this.$refs.exerciseForm.reset()
            this.name = ''
            this.description = 'TBD'
            this.instructions = 'TBD'
            this.dificulty = 'Intermediate'
            this.primary = []
            this.secondary = []
            this.stabilizers = []
            this.equipment = []
            this.theforceType = { name: 'Push' }
            this.mechanicsType = { name: 'Compound' }
            this.utilityType = { name: 'Basic' }
            this.$emit('done', exercise)
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    loadMusclesDefinition() {
      {
        return axios
          .get(this.baseURL + '/util/muscles/all', {})
          .then(response => {
            if (response.status == 200) {
              this.musclesList = response.data.data
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    },
    checkCode() {
      {
        return axios
          .get(this.baseURL + '/exercises/checkcode/' + this.code, {})
          .then(response => {
            if (response.status == 200) {
              this.codeUnique = response.data.data.is_unique == 'Yes'
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    },
    loadEquipment() {
      {
        return axios
          .get(this.baseURL + '/util/equipment/all', {})
          .then(response => {
            if (response.status == 200) {
              this.equipmentList = response.data.data
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    },
    loadGlossary() {
      return axios
        .get(this.baseURL + '/util/glossary/exercise', {})
        .then(response => {
          if (response.status == 200) {
            this.glossary = response.data.data

            this.theforces = this.glossary.filter(function(item) {
              return item.category == 'Force'
            })
            this.mechanics = this.glossary.filter(function(item) {
              return item.category == 'Mechanics'
            })
            this.utility = this.glossary.filter(function(item) {
              return item.category == 'Utility'
            })
            this.dificultyList = this.glossary.filter(function(item) {
              return item.category == 'Difficulty'
            })
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    }
  }
}
</script>
